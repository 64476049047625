#start-content {
    height: 100vh;
    padding-left: 12rem;
    @media screen and (max-width: 768px) {
        padding-left: 0;
    }
    .header {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        h1 {
            font-size: 105px;
            margin: 0;
            font-weight: 800;
            line-height: 1.1;
            color: #fff;
            @media screen and (max-width: 768px) {
                font-size: 40px;
                margin: 0 0 0 30px;
            }
            .letter {
                transform-origin: 50% 100%;
                display: inline-block;
                cursor: default;
                .animated {
                    animation-duration: 1s;
                    animation-fill-mode: both;
                    animation-name: rubber-anim;
                    overflow: visible;
                }
                &:hover {
                    color: #72d0ff;
                    overflow: visible !important;
                    top: -17px;
                    position: relative;
                }
            }
        }
        .header-text p {
            color: #8c8d91;
            font-size: 34px;
            max-width: 510px;
            margin: 30px 0px 60px 30px;
            display: inline-block;
            line-height: 40px;
            @media screen and (max-width: 768px) {
                font-size: 28px;
                margin: 5px 0px 60px 30px;
            }
        }

        .contact-btn {
            position: relative;
            cursor: pointer;
            width: fit-content;
            height: 50px;
            margin-left: 30px;
            padding: 16px 51px;
            border: 1px solid #72d0ff;
            border-radius: 4px;
            &:hover {
                background-color: transparent;
                box-shadow: none;
                p {
                    color: #fff;
                }
                &::before {
                    opacity: 0;
                }
                &::after {
                    opacity: 1;
                    transform: scaleX(1) scaleY(1);
                }
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: opacity 0.3s, border 0.3s;
            }
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                border: 1px solid #fff;
                border-radius: 4px;
                opacity: 0;
                z-index: -1;
                transform: scaleX(1.1) scaleY(1.4);
                transform-origin: 50% 56%;
                transition: transform 0.6s, opacity 0.5s;
            }

            p {
                font-family: "Open Sans", sans-serif;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 4px;
                pointer-events: none;
                margin: 0;
                color: #72d0ff;
                transition: color 0.5s;
                line-height: inherit;
            }
        }
    }

    .scroll {
        width: fit-content;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        opacity: 0;
        transform: translateY(-25px);
        p {
            margin-bottom: 4px;
            font-size: 15px;
        }
        img {
            width: 20px;
            animation: downarrow 0.6s infinite alternate ease-in-out;
        }
    }
}

@keyframes downarrow {
    0% {
        -webkit-transform: translateY(0);
        opacity: 0.4;
    }
    100% {
        -webkit-transform: translateY(0.4em);
        opacity: 0.9;
    }
}

@keyframes rubber-anim {
    from {
        transform: scale3d(1, 1, 1);
    }

    30% {
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        transform: scale3d(1.05, 0.95, 1);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}
