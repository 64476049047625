.area {
    width: 50%;
    height: 100vh;
    right: 0;
    position: absolute;
    overflow: hidden;
    pointer-events: none;
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 30s linear infinite;
    top: -150px;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
    background: #7ac2e6 !important;
    margin-top: 100px;
}

.circles li:nth-child(2) {
    left: 15%;
    width: 30px;
    height: 30px;
    animation-delay: 0s;
    animation-duration: 16s;
    margin-top: 300px;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(4) {
    left: 45%;
    width: 60px;
    height: 60px;
    margin-top: 100px;
    animation-delay: 0s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 0s;
}

.circles li:nth-child(7) {
    left: 29%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 0s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 0s;
}

.circles li:nth-child(10) {
    left: 35%;
    width: 120px;
    height: 120px;
    animation-delay: 0s;
    margin-top: -40px;
    background: #7ac2e6 !important;
}
.circles li:nth-child(11) {
    left: 37%;
    width: 50px;
    height: 50px;
    margin-top: 120px;
    animation-delay: 0s;
}

.circles li:nth-child(12) {
    left: 60%;
    width: 90px;
    height: 90px;
    margin-top: 20px;
    animation-delay: 0s;
    border-radius: 50% !important;
}

.circles li:nth-child(13) {
    left: 70%;
    margin-top: 100px;
    width: 120px;
    height: 120px;
    animation-delay: 0s;
}

.circles li:nth-child(14) {
    left: 50%;
    margin-top: -100px;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    border-radius: 50% !important;
    background: #7ac2e6 !important;
}

@keyframes animate {
    0% {
        transform: translateY(300%) rotate(0);
        //top: 30%;
        opacity: 0.2;
        border-radius: 0;
        background: rgba(255, 255, 255, 0.2);
    }
    50% {
        transform: translateY(450%) rotate(120deg);
        //top: 45%;
        opacity: 0.6;
        border-radius: 50%;
        background: #7ac2e6;

    }
    100% {
        transform: translateY(300%) rotate(0);
        //top: 60%;
        opacity: 0.2;
        border-radius: 0;
        background: rgba(255, 255, 255, 0.2);
    }
}
