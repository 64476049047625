@import-normalize;

html,
body {
    display: block;
    height: 100%;
}

body * {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #1a1c21; /*1d1d1d*/
    color: #fff;
    overflow: hidden;
}

#root {
    padding-bottom: 100px;
}

h2 {
    font-size: 70px;
    margin: 0;
    color: #72d0ff
}

p {
    font-size: 1em;
    line-height: 24px;
}

canvas {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 999;
}
